import { useQuery } from '@apollo/react-hooks';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';
import { ContentSubTypeAndStatusQuery } from '@confluence/content-unified-query';
import type { ContentSubTypeAndStatusQueryType } from '@confluence/content-unified-query';

export const useRenderLivePageEarly = ({
	contentId,
	cachedSubType,
	cachedIsArchived,
	renderLivePageAsViewPage,
}: {
	contentId: string;
	cachedSubType?: string;
	cachedIsArchived?: boolean;
	renderLivePageAsViewPage: boolean;
}): boolean => {
	const isLivePageFastLoadEnabled = FeatureGates.checkGate(
		'confluence_frontend_livepages_fastloading',
	);
	const isLivePagesEnabled = useIsLivePagesFeatureEnabled();

	// To handle the scenario where the cached subtype is 'live' but outdated (it's no longer 'live'): We briefly render LivePageComponent, ContentSubTypeAndStatusQuery finishes, then
	// if ContentSubTypeAndStatusQuery returns that the subtype is not actually 'live', we will render the rest of ViewPageCommon rather than rendering LivePageComponent here.
	// To handle the scenario where cached subtype is not 'live' but outdated (it's now 'live'): We'll check for it later in this component, after loading ContentUnifiedQuery,
	// and render LivePageComponent if needed.
	const { data: latestContentData, loading: isContentDataLoading } =
		useQuery<ContentSubTypeAndStatusQueryType>(ContentSubTypeAndStatusQuery, {
			variables: {
				contentId,
			},
			// We don't need to retrieve subtype if cachedSubType is not 'live' because we'll never enter LivePageComponent early.
			skip: !(
				isLivePagesEnabled &&
				isLivePageFastLoadEnabled &&
				!renderLivePageAsViewPage &&
				cachedSubType === 'live'
			),
			fetchPolicy: 'network-only',
		});
	const loadedSubType = latestContentData?.content?.nodes?.[0]?.subType;
	const mostRecentSubType = isContentDataLoading ? cachedSubType : loadedSubType;
	const loadedIsArchived = latestContentData?.content?.nodes?.[0]?.status === 'archived';
	const mostRecentIsArchived = isContentDataLoading ? Boolean(cachedIsArchived) : loadedIsArchived;

	// TODO MODES-4948 Wire up performance tracking here when live page has outdated cache.

	// If live page is archived, render through normal view page instead of live page component
	return (
		isLivePagesEnabled &&
		isLivePageFastLoadEnabled &&
		mostRecentSubType === 'live' &&
		!mostRecentIsArchived
	);
};
